<template>
	<div>
		<CustomTable
			id_table="stock_local"
			ref="table"
			:items="stock"
			:busy.sync="table_busy"
			primaryKey="uid"
    		:columsAdd="['show_stock']"
	        :hrefsRoutes="config_table_hrefs"
    		:externSlotColumns="extern_slot_columns"
		>
			<template v-slot:custom-slot-cell(show_stock)="{ data }">
				<span class="d-flex align-items-center">
					<font-awesome-icon class="previewColIdentifier" @click.stop="showDetails(data)" :icon="['fas', 'eye']" />
				</span>
			</template>
			<template v-slot:[`custom-slot-cell(tiers)`]="{ data }">
				<router-link  v-for="(tier,index) in data.tiers" :key="index" :to="{ name: 'tiersFiche', params: { tiers_id: tier.tiers_id }}">
					{{tier.tiers_rs ? tier.tiers_rs : tier.tiers_firstname + ' ' + tier.tiers_lastname }} <br v-if="index != data.tiers.length">
				</router-link>
			</template>
		</CustomTable>
	</div>
</template>

<script type="text/javascript">
import StockSemence from '@/mixins/StockSemence'
import Shutter from "@/mixins/Shutter"
import ShutterSemence from "@/mixins/shutters-manager/Semence"
import Navigation from "@/mixins/Navigation"

export default {
	name: 'StockLocalList',
	mixins: [StockSemence, ShutterSemence, Shutter, Navigation],
	data () {
		return {
			table_busy: false,
	        stock: [],
	        horses: [],
	        extern_slot_columns: ['show_stock', 'tiers'],
	        config_table_hrefs: {
				'horse.horse_nom': {
					routeUniqueName: 'StallionFiche',
					routeName: 'horseFiche',
					params: {
						horse_id: 'horse.horse_id'
					}
				},
				'to.tiers_rs': {
					routeUniqueName: 'tiersTableauBord',
					routeName: 'tiersTableauBord',
					params: {
						tiers_id: 'to.tiers_id'
					}
				},
			},
			events_tab: {
                'TableAction::goToImportStock': this.showImportForm,
                'TableAction::goToSortieStock': this.showSortieForm
            }
		}
	},

	created() {
		this.init_component()
	},

	methods: {
		async init_component() {
			this.table_busy = true
			this.stock = await this.loadAllStockLocal()
			this.table_busy = false

			this.horses = this.stock.map(s => s.horse)
		},

		showDetails(stock) {
			this.setupStallionDetails(stock.horse, stock.to, false)
		},

		showImportForm() {
			this.setUpImportStock()
		},

		showSortieForm() {
			this.setUpSortieStock(this.horses)
		}
	},

	components: {
		CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
	}
};

</script>